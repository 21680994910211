import React, { useEffect, useState } from 'react'
import './insight.scss'
import PostOne from '../../../images/insight/post-one.png'
import PostTwo from '../../../images/insight/post-two.png'
import PostThree from '../../../images/insight/post-three.png'
import PostFour from '../../../images/insight/post-four.png'
import PostFive from '../../../images/insight/post-five.png'
import ArrowRight from '../../../images/icons/arrow-right.svg'
import Axios from 'axios';
import ClipLoader from "react-spinners/ClipLoader";
import { Link } from 'gatsby'

const Insight = () => {

    const [post, setPost] = useState([])
    const [loader, setLoader] = useState(false)

    const getPosts = async () => {
        setLoader(true)
        try {
            const results = await Axios.get('https://procentriciq.com/portal/api/posts')

            if (results.status === 200) {
                setPost(results.data.response.posts)
            }
            // console.log(results.data.response.posts)
        } catch (error) {
            console.error(error)
        }
        finally {
            setLoader(false)
        }
    }

    const dateFormatter = (date) => {
        const unix = Date.parse(date)
        const convertedDate = new Date(unix)
        let months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        let year = convertedDate.getFullYear();
        let month = months[convertedDate.getMonth()];
        let day = convertedDate.getDate();

        return day + ' ' + month + ', ' + year
    }

    useEffect(() => {
        getPosts()
    }, [])

    return (
        <div className="my-md-5 py-md-5 bg-insight">
            <div className="py-5">
                <div className="container">
                    <div className="insight">
                        <h1 className="text-center font-weight-bold">Insights</h1>
                        <p className="text-center mb-5 pb-5" style={{ fontSize: '1.25rem' }}>Get the latest information about our business and people</p>

                        <div className="my-md-5 py-md-5"></div>

                        {loader &&
                            <div className="text-center">
                                <ClipLoader

                                />
                                <h5 className="pt-1">Loading...</h5>
                            </div>
                        }

                        {!loader &&
                            <div className="my-5 py-5">
                                <div className="row">
                                    {
                                        post.length > 0 && <div className="col-md-8 mb-md-0 mb-5 pb-md-0 pb-4">
                                            <div className="post-bg">
                                                <img src={post.length > 0 ? post[0].image : ''} alt="background" />
                                            </div>

                                            <div className="row">
                                                <div className="col-7">
                                                    <p className="text-uppercase mt-4 inner-page-breadcrumb"><span className="text-secondary mr-2">{post.length > 0 ? post[0].category.name : ''}</span>{post.length > 0 ? (post[0].user.first_name + ' ' + post[0].user.last_name) : ''}</p>
                                                </div>

                                                <div className="col-5 text-right">
                                                    <p className="text-uppercase mt-4 inner-page-breadcrumb">{post.length > 0 ? dateFormatter(post[0].created_at) : ''}</p>
                                                </div>
                                            </div>


                                            <Link to={`/individual-post`} state={{ selectedPost: post[0] }}>
                                                <h4 className="cursor black-link-text" style={{ lineHeight: '30px', fontWeight: '400' }}>{post.length > 0 ? post[0].title : ''}</h4>
                                            </Link>

                                            <Link to={`/individual-post`} state={{ selectedPost: post[0] }}>
                                                <p className=" cursor black-link-text mb-0 pt-4 my-auto">Read Article <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                                            </Link>
                                        </div>
                                    }

                                    {
                                        post.length > 0 && <div className="col-md-4 mb-md-0 mb-5 pb-md-0 pb-4">
                                            <div className="post-bg">
                                                <img src={post.length > 0 ? post[1].image : ''} alt="background" />
                                            </div>

                                            <div className="row">
                                                <div className="col-7">
                                                    <p className="text-uppercase mt-4 inner-page-breadcrumb"><span className="text-secondary mr-2">{post.length > 0 ? post[1].category.name : ''}</span>{post.length > 0 ? (post[1].user.first_name + ' ' + post[1].user.last_name) : ''}</p>
                                                </div>

                                                <div className="col-5 text-right">
                                                    <p className="text-uppercase mt-4 inner-page-breadcrumb">{post.length > 0 ? dateFormatter(post[1].created_at) : ''}</p>
                                                </div>
                                            </div>

                                            <Link to={`/individual-post`} state={{ selectedPost: post[1] }}>
                                                <h4 className="cursor black-link-text" style={{ lineHeight: '30px', fontWeight: '400' }}>{post.length > 0 ? post[1].title : ''}</h4>
                                            </Link>

                                            <Link to={`/individual-post`} state={{ selectedPost: post[1]}}>
                                            <p className=" cursor black-link-text mb-0 pt-4 my-auto">Read Article <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                                            </Link>
                                        </div>
                                    }

                                </div>


                                {/* <div className="row mt-5">
                                    <div className="col-md-4 mb-md-0 mb-5 pb-md-0 pb-4">
                                        <div className="post-bg">
                                            <img src={PostThree} alt="background" />
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-uppercase mt-4 inner-page-breadcrumb"><span className="text-secondary mr-2">Digital</span>ofe ivharue</p>
                                            </div>

                                            <div className="col-6 text-right">
                                                <p className="text-uppercase inner-page-breadcrumb mt-4">September 1, 2020</p>
                                            </div>
                                        </div>

                                        <h4 className="cursor black-link-text" style={{ lineHeight: '30px', fontWeight: '400' }}>Reinforcing network security for a global conglomerate</h4>

                                        <p className=" cursor black-link-text mb-0 pt-4 my-auto">Read Article <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                                    </div>

                                    <div className="col-md-4 mb-md-0 mb-5 pb-md-0 pb-4">
                                        <div className="post-bg">
                                            <img src={PostFour} alt="background" />
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-uppercase inner-page-breadcrumb mt-4"><span className="text-secondary mr-2">Digital</span>ofe ivharue</p>
                                            </div>

                                            <div className="col-6 text-right">
                                                <p className="text-uppercase inner-page-breadcrumb mt-4">September 1, 2020</p>
                                            </div>
                                        </div>

                                        <h4 className="cursor black-link-text" style={{ lineHeight: '30px', fontWeight: '400' }}>But I must explain to you how all this mistaken idea of denouncing.</h4>

                                        <p className=" cursor black-link-text mb-0 pt-4 my-auto">Read Article <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                                    </div>

                                    <div className="col-md-4 mb-md-0 mb-5 pb-md-0 pb-4">
                                        <div className="post-bg">
                                            <img src={PostFive} alt="background" />
                                        </div>

                                        <div className="row">
                                            <div className="col-6">
                                                <p className="text-uppercase inner-page-breadcrumb mt-4"><span className="text-secondary mr-2">Digital</span>ofe ivharue</p>
                                            </div>

                                            <div className="col-6 text-right">
                                                <p className="text-uppercase inner-page-breadcrumb mt-4">September 1, 2020</p>
                                            </div>
                                        </div>

                                        <h4 className="cursor black-link-text" style={{ lineHeight: '30px', fontWeight: '400' }}>Reinforcing network security for a global conglomerate.</h4>

                                        <p className=" cursor black-link-text mb-0 pt-4 my-auto">Read Article <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p>
                                    </div>
                                </div> */}
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Insight
