import React from 'react'
import Layout from '../components/layout'
import ArrowRight from '../images/icons/arrow-right.svg'
import '../css/risk-management.scss'
import Nav from '../components/nav/nav'
import Insight from './home/insight/insight'
import RiskLetter from '../images/icons/risk.svg'
import { Link } from 'gatsby'

const RiskManagement = () => {
    return (
        <Layout>
            <Nav
                isWhite={true}
            />

            <div className="risk-management pt-5 position-relative">

                <div className="position-relative">
                    {/* hero section */}
                    <div className="bg-risk py-5">
                        <div className="container py-3 my-4">
                            <div className="row">
                                <div className="col-md-8">
                                    <div className="row">
                                        <div className="col-md-1 px-0 mt-4">
                                            <div style={{ borderBottom: '3px solid #ffffff' }}></div>
                                        </div>
                                        <div className="col-md-8">
                                            <h1 className="text-white font-weight-bold" style={{ lineHeight: '58px', fontSize: '2.8rem' }}>Risk Management</h1>

                                            <p className="text-white mb-0" style={{ lineHeight: '28px', fontSize: '1.3rem' }}>Take precautionary steps to protect your business, customers and workforce from potential risks.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-4 my-auto">
                                    <p className="off-white mb-0" style={{ lineHeight: '26px', fontSize: '1.06rem' }}>We understand the complexities and challenges of security threats businesses face. Let us work with you to identify potential risks in advance, and take a consistent approach to finding and applying resolution strategies before it is too late.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="risk-letter-div mb-5">
                        <img src={RiskLetter} alt="" className="risk-img" />
                    </div>
                </div>

                <div className="my-5 py-5"></div>
                <div className="my-md-5 py-md-5"></div>


                <div className="container my-5 py-5">
                    <div className="bg-cyber py-5">
                        <div className="p-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h4 className="mb-4"> - Cyber security</h4>

                                                <p className="text-secondary" style={{lineHeight: '26px'}}>We will arm your business with a portfolio of security tools, honed to fight against online and networking malware; deploying security counter-measures in cases of compromised assets, and barricading your business against future incidents. </p>

                                                <p className="text-secondary mb-1" style={{lineHeight: '26px'}}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0"  style={{lineHeight: '26px'}}>Create security measures without loopholes.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Protect your assets from threats.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Run vulnerability assessment to expose threats beforehand.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Resolve cases of compromised security and assets.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Defend your servers, networks, and systems from malicious attacks.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Foster safe conduct of transactions across departments and teams.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                    <Link to={`/contact`} state={{ path: 'risk' }}><button className="btn btn-red btn-small mr-4 text-uppercase btn-red-black px-4 no-border-radius">start now</button></Link>

                                                    <Link className="my-auto" to="/security-usecase"><p className="cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container my-5 py-5">
                    <div className="bg-data-protection py-5">
                        <div className="p-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6"></div>
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h4 className="mb-4"> - Data protection</h4>

                                                <p className="text-secondary" style={{lineHeight: '26px'}}>We will work with you to safeguard important information of your customers, putting checks in place to ensure you capture and store data in a way that upholds the fundamental rights and freedom of persons in compliance with regulatory standards (NDPR). </p>

                                                <p className="text-secondary" style={{lineHeight: '26px'}}>Review our Data Protection Compliance Service Catalogue<Link to="/data-protection-catalogue"><button className="btn btn-blue btn-extra-small ml-2 text-uppercase btn-red-black px-4 no-border-radius">Download</button></Link></p>

                                                <p className="text-secondary mb-1" style={{lineHeight: '26px'}}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Protect data and infrastructure.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Operate with critical data in a risk free environment.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Stay on the right side of the law by complying to data regulations.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Constantly review regulations to ensure your business is always NDPR compliant.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Identify relevant areas of default and make applicable remediation.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Prepare, file and submit data protection audit reports with NITDA.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}> Foster safe conduct in terms of data transaction within and outside your organization.</p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Train your workforce on data protection.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                    <Link to={`/contact`} state={{ path: 'risk' }}><button className="btn btn-red btn-small mr-4 text-uppercase btn-red-black px-4 no-border-radius">start now</button></Link>

                                                    <Link className="my-auto" to="/security-usecase"><p className="cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container my-5 py-5">
                    <div className="bg-network py-5">
                        <div className="p-md-5">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="card p-4">
                                            <div className="p-3">
                                                <h4 className="mb-4"> - Network security and audit</h4>

                                                <p className="text-secondary" style={{lineHeight: '26px'}}>We will keep your business on the right side of the law at all times, ensuring your business and workforce adhere to regulatory standards even as they change, and conduct everyday operations within ethical boundaries.</p>

                                                <p className="text-secondary mb-1" style={{lineHeight: '26px'}}>We will help you;</p>

                                                <ul>
                                                    <li><p className="text-secondary mb-0"  style={{lineHeight: '26px'}}>Create better collaboration suites. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Build a digital-first mindset across operations. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Make better and faster decisions using data. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Maintain a competitive workforce with tools that will meet the needs of your digital workplace. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Integrate your systems to meet changing business and market requirements. </p></li>
                                                    <li><p className="text-secondary mb-0" style={{lineHeight: '26px'}}>Stay on top of fast and frequently changing digital technology to solve problems uniquely.</p></li>
                                                </ul>

                                                <div className="d-inline-flex mt-5">
                                                    <Link to={`/contact`} state={{ path: 'risk' }}><button className="btn btn-red btn-small mr-4 text-uppercase btn-red-black px-4 no-border-radius">start now</button></Link>

                                                    <Link className="my-auto" to="/security-usecase"><p className="cursor black-link-text mb-0 my-auto">Learn more <img className="ml-3" src={ArrowRight} alt="arrow right" style={{ maxWidth: '6px' }} /></p></Link>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div className="my-md-5 my-0 py-md-5 py-0"></div>
            <Insight />
        </Layout>
    )
}

export default RiskManagement
